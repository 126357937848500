import React from 'react';

const Help = () => {
  return (
    <section id="donate" className="donate">
      <h2>Support Our Cause</h2>
      <img src="/images/donate.jpg" alt="SPCA" />
      <br /><p>Your generous donations enable us to continue supporting animals in need. Every contribution, no matter the size, makes a significant impact. Please explore the donation options below to contribute.</p>
      <div className="donate-options">
        <a
          href="https://paypal.com/us/fundraiser/charity/5301004"
          target="_blank"
          rel="noopener noreferrer"
          className="donate-link"
        >
          <span>Donate via PayPal</span>
        </a>
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=TDYTYGA7XUJVA"
          target="_blank"
          rel="noopener noreferrer"
          className="donate-link"
        >
          <span>Donate with Debit or Credit Card</span>
        </a>
        <a
          href="mailto:sahayogspcanepal@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
          className="donate-link"
        >
          <span>Donate Via Zelle - sahayogspcanepal@gmail.com</span>
        </a>
      </div>
      <br />
      <br /><strong><p>Or, Become a member:</p></strong>
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSe6si5m90LR1ldR9xqxOhcnPS0Eb4bJHZn37t7OVAdl1pSGOA/viewform"
        target="_blank"
        rel="noopener noreferrer"
        className="gofundme-button"
      >
        Sahayog's Membership Form
      </a>
      <br />
      <br /><strong><p>Visit our Campaigns:</p></strong>
      <a
        href="https://www.paypal.com/donate?campaign_id=SDH85UXR7SJTG"
        target="_blank"
        rel="noopener noreferrer"
        className="gofundme-button"
      >
        Help SPCA Nepal to Spay Neuter Street Animals
      </a>
      <a
        href="https://www.paypal.com/donate?campaign_id=QY8TEMS8DRV66"
        target="_blank"
        rel="noopener noreferrer"
        className="gofundme-button"
      >
        Help SPCA Nepal Treat Dogs with Mange Disease
      </a>
      <a
        href="https://paypal.com/donate?campaign_id=YDB4KUQ4HZ8P6"
        target="_blank"
        rel="noopener noreferrer"
        className="gofundme-button"
      >
        Help SPCA Nepal Treat Dogs with CTVT
      </a>
      <a
        href="https://www.paypal.com/donate?campaign_id=DMT4WMRF2PKEU"
        target="_blank"
        rel="noopener noreferrer"
        className="gofundme-button"
      >
        Help SPCA Nepal Relocate Permanently
      </a>
      <a
        href="https://www.paypal.com/donate?campaign_id=CN85F7RRGBRXW"
        target="_blank"
        rel="noopener noreferrer"
        className="gofundme-button"
      >
        Urgent Help Needed SPCA Nepal Shelter Expansion
      </a>
      <a
        href="https://www.paypal.com/donate?campaign_id=YVXQAYL4WP6QU"
        target="_blank"
        rel="noopener noreferrer"
        className="gofundme-button"
      >
        Feed the Shelter Dogs in Kukur Tihar Celebration
      </a>
    </section>
  );
}

export default Help;
